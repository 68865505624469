html,
body {
  font-size: 16px;
  font-family: "Ciutadella Rounded";
  color: rgb(48, 48, 48);
}

.cdx-input {
  border: none;
}

.image-tool__image {
  background-color: transparent;
}

.image-tool__image img {
  margin: auto;
  display: block;
  border-radius: 10px;
}

.image-tool__caption:empty {
  display: none;
}
 
.alerts-border {
  
  border: 4px solid #9ae2ff;
  border-radius: 6px;
  padding: 1px;
  animation-name: blinking;
  animation-duration: 1s;
  animation-iteration-count: 100;
}

@keyframes blinking {
  0% {
    border: 4px solid red;
  }
  100% {
    border: 4px solid transparent;
  }
}

/* 🔹 Force scrolling inside Ionic Select Alert */
.alert-radio-group {
  max-height: 300px !important; /* Adjust height to fit your UI */
  overflow-y: auto !important; /* Enables scrolling */
  display: flex;
  flex-direction: column;
}

/* 🔹 Ensure each dropdown option remains visible */
.select-interface-option {
  white-space: normal !important; /* Wraps long text */
  overflow: hidden;
  text-overflow: ellipsis;
}

/* 🔹 Force scrollbar visibility */
.alert-radio-group::-webkit-scrollbar {
  width: 12px; /* Scrollbar width */
}

.alert-radio-group::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 4px;
}

.alert-radio-group {
  scrollbar-width: thin; /* Firefox scrollbar */
}


ion-list.list-md {
  padding-bottom: 0;
  padding-top: 0;

  ion-list-header {
    --border-color: var(--ion-color-primary);
    margin: 2rem 0;
    padding-left: 0;
  }

  .item-label {
    margin: 1rem 0;

    .label-stacked {
      margin-bottom: 1rem;
    }
  }
}

ion-select {
  width: 100%;
}

.select-interface-option.alert-tappable{
  display: contents;
  margin: 1rem 0 !important ;

  .alert-radio-label {
    text-overflow: initial  ;
    white-space: normal  ;
  }
}

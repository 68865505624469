/*
 * Generates common classes 'margin-top-i', with i a number between 1 and 10 
 */
$maxLevel: 10;

ion-app {
  @for $i from 1 through $maxLevel {
    .marg-top-#{$i} {
      margin-top: #{$i}rem;
    }
  }
}

.d-flex {
  display: flex;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.w-container {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

.overflow-scroll {
  max-height: 80vh;
  overflow-y: scroll;
}

ion-modal {
  .ion-page {
    padding: 2rem;
    overflow: auto;
  }
}

.modal-wrapper{
  background-color: transparent!important;
  box-shadow: none!important;
}

.resonance-modal .ion-page {
  background: none;
}
ion-label {
  .label-with-image {
    display: flex;
    align-items: center;
    gap: 2rem;
  }

  .color-primary {
    color: var(--ion-color-primary);
  }

  .res-text-input {
    font-size: 14px;
    line-height: 24px;
  }
  
  .res-text-small {
    font-size: 12px;
    line-height: 16px;
  }

  .answer-with-scroll {
    white-space: break-spaces;
  }
}
